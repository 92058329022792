<template>
  <div>
    <div class="pdf-sheet" ref="pdfSheet" v-if="dataExis">

      <section>
        <div class="header">
          <div style="max-width: 130px;">
            <img style="width: 100%;" src="../../assets/images/logo.png" />
          </div>
          <h4>FORM INFORMASI JABATAN</h4>
          <p style="padding: 0px 0.5rem; margin: 0px">{{ currYear }}</p>
        </div>
      </section>

      <section class="content">

        <div style="padding: 5px 0px ;border: 1px solid black; width: 100%;">
          <table class="table-pdf" style="width: 100%;">
            <tbody>
              <tr v-for="(item, idx) in tableContent1" :key="idx">
                <td class="title" style="width: 25%">{{ item.name }}</td>
                <td class="title" style="width: 1%">:</td>
                <td v-if="item.value == 'sector'">{{ `${data.kode_sector}.${data.sector}` }}</td>
                <td v-else-if="item.value == 'desc'">{{ `${data.kode}.${data.desc}` }}</td>
                <td v-else>{{ data[item.value] }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="padding: 5px 0px ;border: 1px solid black; width: 100%;">
          <table class="table-pdf">
            <tbody>
              <tr>
                <td class="title" style="width: 25%">Rekomendasi ragam disabilitas</td>
                <td class="title" style="width: 1%">:</td>
                <div v-for="(item, idx) in data.ragamdisabilitas" :key="idx">
                    {{ `${idx + 1}. ${item.disabilitas}` }}
                  </div>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="padding: 5px 0px ;border: 1px solid black; width: 100%;">
          <table class="table-pdf">
            <tbody>
              <tr>
                <td class="title" style="width: 25%">Ringkasan Uraian Tugas</td>
                <td class="title" style="width: 1%">:</td>
                <td>{{ data.ringkasan_uraian_tugas }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="padding: 5px 0px ;border: 1px solid black; width: 100%;">
          <table class="table-pdf">
            <tbody>
              <tr>
                <td class="title" style="width: 25%; vertical-align: top;">
                  Uraian Tugas
                </td>
                <td class="title" style="width: 1%">:</td>
                <td>
                  <div v-for="(item, idx) in data.rincianTugas" :key="idx">
                    {{ `${idx + 1}. ${item.rincian_tugas}` }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="breakPdf1" class="html2pdf__page-break"></div>

        <div style="padding: 5px 0px; border: 1px solid black; width: 100%;" ref="table2Show">
          <table class="table-pdf">
            <tbody>
              <tr v-for="(item, idx) in tableContent2" :key="idx">
                <td class="title" style="width: 25%">{{ item.name }}</td>
                <td class="title" style="width: 1%">:</td>
                <td v-if="data.syarat[item.data].length > 0">
                  <div v-for="(content, idx) in data.syarat[item.data]" :key="idx">
                    {{ `${idx + 1}. ${content[item.value]}` }}
                  </div>
                </td>
                <td v-else>
                  Data Belum Tersedia
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="breakPdf2" class="html2pdf__page-break"></div>

        <div style="padding: 5px 0px ;border: 1px solid black; width: 100%;" ref="table3Show">
          <table class="table-pdf">
            <tbody>
              <tr v-for="(item, idx) in tableContent3" :key="idx">
                <td class="title" style="width: 25%">{{ item.name }}</td>
                <td class="title" style="width: 1%">:</td>
                <td v-if="data.syarat[item.data].length > 0">
                  <div v-for="(content, idx) in data.syarat[item.data]" :key="idx">
                    {{ `${idx + 1}. ${content[item.value]} (${content[item.code]})` }}
                  </div>
                </td>
                <td v-else>
                  Data Belum Tersedia
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="breakPdf3" class="html2pdf__page-break"></div>

      </section>


      <section class="footer" ref="tableFooter">
        <p>e-Jabatan mengundang Anda untuk melakukan tinjauan dan memberikan usulan untuk memperbarui data jabatan dari informasi yang tersaji dengan data yang relevan.</p>
<pre>
Direktorat Bina Penempatan Tenaga Kerja Dalam Negeri
Direktorat Jenderal Pembinaan Penempatan Tenaga Kerja dan Perluasan Kesempatan Kerja
email: apbj@kemnaker.go.id
</pre>
      </section>

      </div>
  </div>
</template>

<script>
import api from './api.js'
import auth from '../../helpers/auth'
import swal from 'sweetalert2'

export default {
  props: {
    informasi_id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currYear: '',
      tableContent1: [
        {
          name: 'Nama Jabatan',
          value: 'nama_jabatan'
        },
        {
          name: 'Padanan Nama Jabatan',
          value: 'padanan_nama_jabatan'
        },
        {
          name: 'Kode Jabatan',
          value: 'kode_jabatan'
        },
        {
          name: 'Kategori',
          value: 'sector'
        },
        {
          name: 'Golongan Pokok',
          value: 'desc'
        },
      ],
      tableContent2: [
        {
          name: 'Pendidikan',
          data: 'pendidikan',
          value: 'pendidikan'
        },
        {
          name: 'Kompetensi',
          data: 'kompetensi',
          value: 'kompetensi'
        },
        {
          name: 'Pengetahuan Kerja',
          data: 'pengetahuanKerja',
          value: 'pengetahuan_kerja'
        },
        {
          name: 'Pelatihan Kerja',
          data: 'pelatihanKerja',
          value: 'pelatihankerja'
        },
      ],
      tableContent3: [
      {
          name: 'Syarat Upaya Fisik',
          data: 'upayaFisik',
          value: 'syaratfisik',
          code: 'r_kode'
        },
        {
          name: 'Syarat Bakat',
          data: 'bakat',
          value: 'bakat',
          code: 'r_kode'
        },
        {
          name: 'Syarat Minat',
          data: 'minat',
          value: 'minat',
          code: 'r_kode'
        },
        {
          name: 'Syarat Tempramen',
          data: 'tempramen',
          value: 'tempramen',
          code: 'r_kode'
        },
      ],
      testData: [
        'test', 'test', 'test'
      ],
      data: [],
      informasi_id_dummy: '6906',
      dataExis: false,
      breakPdf1: false,
      breakPdf2: false,
      breakPdf3: false
    }
  },
  watch: {
  },
  async mounted () {
    const currDate = new Date()
    this.currYear = currDate.getFullYear()
    await this.getInformasiData()
    this.dataExis = true
    const ref = this.$refs
    const table2 = this.$refs.table2Show
    //console.log('ref', ref)
    //console.log('table2', table2)
    //console.log('table2Height', table2.clientHeight)
  },
  updated () {
    //console.log('ref', this.$refs.pdfSheet.clientHeight)
    //console.log('table2', this.$refs.table2Show)
    //console.log('table2Height', this.$refs.table2Show.clientHeight)
    //console.log('table2 y position', this.$refs.table2Show.getBoundingClientRect().y)
    //console.log('table2Height + table2 y position', this.$refs.table2Show.getBoundingClientRect().y + this.$refs.table2Show.clientHeight)
    //console.log('table3', this.$refs.table3Show)
    //console.log('table3Height', this.$refs.table3Show.clientHeight)
    //console.log('table3 y position', this.$refs.table3Show.getBoundingClientRect().y)
    const innerHeight = this.$refs.pdfSheet.clientHeight
    const table2 = this.$refs.table2Show
    const table3 = this.$refs.table3Show
    const tableFooter = this.$refs.tableFooter
    // 760

    if (table2.getBoundingClientRect().y <= 752 && table2.getBoundingClientRect().y + table2.clientHeight >= 770) {
      this.breakPdf1 = true
    } else if (table3.getBoundingClientRect().y <= 752 && table3.getBoundingClientRect().y + table3.clientHeight >= 770) {
      this.breakPdf2 = true
    } else if (tableFooter.getBoundingClientRect().y <= 752 && tableFooter.getBoundingClientRect().y >= 770) {
      this.breakPdf3 = true
    }
    // if (innerHeight > 850) {
      
    //   // this.breakPdf = true
    // }
  },
  methods: {
    async getInformasiData () {
      try {
        //console.log('informasi_id', this.informasi_id)
        // if (!this.informasi_id || this.informasi_id == '') return ''
        const informasi_id = this.informasi_id ? this.informasi_id : 61
        const param = {
          token: auth.getSSOToken()
        }
        // const res = await api.getDownloadData(this.informasi_id, param, { Authorization: `Bearer ${auth.getToken()}` })
        const res = await api.getDownloadDataDisabilitas(informasi_id, param, { Authorization: `Bearer ${auth.getToken()}` })
        // const res = await api.getDownloadData(this.informasi_id, { Authorization: `Bearer ${auth.getToken()}` })
        // const res = await api.getJabatanDetail(this.informasi_id)
        //console.log('res', res)
        let ragamdisabilitas = sortByKey(res.r_ragamdisabilitas,'disabilitas');

        const dataDetail = {
          jabatanId: res.id,
          nama_jabatan: res.nama_jabatan,
          padanan_nama_jabatan: res.padanan_nama_jabatan ? res.padanan_nama_jabatan : '-',
          kode_jabatan: res.kode_jabatan,
          kode_sector: res.sector.kode,
          kode: res.subsector[0].ss_kode,
          sector: res.sector.sector,
          sectorId: res.sector.id,
          desc: res.subsector[0].subsector,
          subSectorId: res.subsector[0].id,
          ringkasan_uraian_tugas: res.ringkasan_uraian_tugas,
          rincianTugas: res.d_rinciantugas,
          ragamdisabilitas: ragamdisabilitas,
          syarat: {
            pendidikan: res.r_pendidikan,
            pengetahuanKerja: res.d_pengetahuankerja,
            pelatihanKerja: res.d_pelatihankerja,
            upayaFisik: res.r_syaratfisik,
            bakat: res.r_bakat,
            minat: res.r_minat,
            tempramen: res.r_tempramen,
            kompetensi: res.r_kompetensi,
          }
        }
        //console.log('dataDetail', dataDetail)

        this.data = dataDetail
      } catch (err) {
        //console.log('err', err)
        // swal.fire({
        //   icon: 'error',
        //   text: 'Gagal mendapatkan data pdf'
        // })
        this.$emit('failedGenerate')
      }
      // this.innerHeight = this.$refs.pdfSheet.clientHeight
    }
  }
}
function // Function to sort by a specific key
    sortByKey(array, key) {
        return array.sort((a, b) => {
            let keyA = a[key].toLowerCase();
            let keyB = b[key].toLowerCase();
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }
</script>

<style>
.pdf-sheet > * {
  padding: 3rem 3rem 0px 3rem;
  font-size: 13px;
  /* min-height: 29.7cm;
  max-height: 29.7cm;
  min-width: 21cm;
  max-width: 21cm;
  border: 1px solid black; */
  /* margin: 30mm 45mm 30mm 45mm; */
}
/* .pdf-sheet {
  size: 21cm 29.7cm;
  margin: 30mm 45mm 30mm 45mm;
} */

.header {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  /* width: 100%; */
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.table-pdf{
  display: block;
  max-width: 100%;
  word-wrap: break-word;         /* All browsers since IE 5.5+ */
  overflow-wrap: break-word;     /* Renamed property in CSS3 draft spec */
}

.table-pdf td {
  padding: 0px 0.5rem;
  word-wrap: break-word;         /* All browsers since IE 5.5+ */
  overflow-wrap: break-word;     /* Renamed property in CSS3 draft spec */
}

.table-pdf .title {
  vertical-align: top;
}
</style>